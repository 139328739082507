<template>
  <component :is="dashboard" />
</template>
<script>
import authentication from 'mixins/authentication'
import loading from 'utils/loading'

export default {
  components: {
    mapdiscovery: () => import('pages/dashboard/map-discovery'),
    backoffice: () => import('pages/dashboard/backoffice')
  },
  mixins: [authentication],
  computed: {
    dashboard () {
      return this.hasRole(['backoffice', 'admin']) ? 'backoffice' : 'mapdiscovery'
    }
  },
  created () {
    // Clear the store to prevent booking leaks
    this.$store.commit('ondemand/clear')
    if (this.dashboard !== 'backoffice') this.$store.dispatch('addresses/getSavedLocations')
    if (this.$route.params.booking_reference) loading.stop()
  }
}
</script>
